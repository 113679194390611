
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import { MrUiKitButton, MrUiKitModal } from "mr-ui-kit";
import { helpers } from "@vuelidate/validators";
import { Dealer, UserRole } from "@/interfaces";
import DealerServices from "@/services/DealerServices";
import * as _ from "lodash";
import { useStore } from "vuex";

export default defineComponent({
  name: "AddDealer",
  emits: ["close", "delete"],
  components: {
    MrUiKitButton,
    MrUiKitModal,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Sicuro di voler procedere con l'eliminazione?",
    },
    element: {
      default: null,
    },
    check: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const isMounted = ref(false);

    const requiredWithTrue = helpers.withMessage(
      "Per eliminare l'elemento è necessario confermare",
      (value) => {
        return value === true ?? false;
      }
    );

    const formFields = reactive(
      props.check
        ? [
            {
              component: "MrUiKitToggle",
              name: "confirmToggle",
              props: {
                label: "Conferma eliminazione",
              },
              rules: {
                requiredWithTrue,
              },
            },
          ]
        : []
    );

    const form = reactive<any>({
      confirmToggle: false,
    });

    onMounted(() => {
      isMounted.value = true;
    });

    const onSubmit = async () => {
      emit("delete", props.element);
    };

    const onClose = () => {
      emit("close");
    };

    return {
      form,
      formFields,
      isMounted,
      onSubmit,
      onClose,
    };
  },
});
